/*---------------------------------------------
- Margins
---------------------------------------------*/

.m-none {
  margin: 0 !important;
}
.ml-none {
  margin-left: 0 !important;
}
.mt-none {
  margin-top: 0 !important;
}
.mr-none {
  margin-right: 0 !important;
}
.mb-none {
  margin-bottom: 0 !important;
}
.mx-none {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.m-auto {
  margin: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.m-xxs {
  margin: var(--spacing-xxs) !important;
}
.ml-xxs {
  margin-left: var(--spacing-xxs) !important;
}
.mt-xxs {
  margin-top: var(--spacing-xxs) !important;
}
.mr-xxs {
  margin-right: var(--spacing-xxs) !important;
}
.mb-xxs {
  margin-bottom: var(--spacing-xxs) !important;
}
.mx-xxs {
  margin-left: var(--spacing-xxs) !important;
  margin-right: var(--spacing-xxs) !important;
}
.my-xxs {
  margin-top: var(--spacing-xxs) !important;
  margin-bottom: var(--spacing-xxs) !important;
}
.m-xs {
  margin: var(--spacing-xs) !important;
}
.ml-xs {
  margin-left: var(--spacing-xs) !important;
}
.mt-xs {
  margin-top: var(--spacing-xs) !important;
}
.mr-xs {
  margin-right: var(--spacing-xs) !important;
}
.mb-xs {
  margin-bottom: var(--spacing-xs) !important;
}
.mx-xs {
  margin-left: var(--spacing-xs) !important;
  margin-right: var(--spacing-xs) !important;
}
.my-xs {
  margin-top: var(--spacing-xs) !important;
  margin-bottom: var(--spacing-xs) !important;
}
.m-sm {
  margin: var(--spacing-sm) !important;
}
.ml-sm {
  margin-left: var(--spacing-sm) !important;
}
.mt-sm {
  margin-top: var(--spacing-sm) !important;
}
.mr-sm {
  margin-right: var(--spacing-sm) !important;
}
.mb-sm {
  margin-bottom: var(--spacing-sm) !important;
}
.mx-sm {
  margin-left: var(--spacing-sm) !important;
  margin-right: var(--spacing-sm) !important;
}
.my-sm {
  margin-top: var(--spacing-sm) !important;
  margin-bottom: var(--spacing-sm) !important;
}
.m-md {
  margin: var(--spacing-md) !important;
}
.ml-md {
  margin-left: var(--spacing-md) !important;
}
.mt-md {
  margin-top: var(--spacing-md) !important;
}
.mr-md {
  margin-right: var(--spacing-md) !important;
}
.mb-md {
  margin-bottom: var(--spacing-md) !important;
}
.mx-md {
  margin-left: var(--spacing-md) !important;
  margin-right: var(--spacing-md) !important;
}
.my-md {
  margin-top: var(--spacing-md) !important;
  margin-bottom: var(--spacing-md) !important;
}
.m-lg {
  margin: var(--spacing-lg) !important;
}
.ml-lg {
  margin-left: var(--spacing-lg) !important;
}
.mt-lg {
  margin-top: var(--spacing-lg) !important;
}
.mr-lg {
  margin-right: var(--spacing-lg) !important;
}
.mb-lg {
  margin-bottom: var(--spacing-lg) !important;
}
.mx-lg {
  margin-left: var(--spacing-lg) !important;
  margin-right: var(--spacing-lg) !important;
}
.my-lg {
  margin-top: var(--spacing-lg) !important;
  margin-bottom: var(--spacing-lg) !important;
}
.m-xl {
  margin: var(--spacing-xl) !important;
}
.ml-xl {
  margin-left: var(--spacing-xl) !important;
}
.mt-xl {
  margin-top: var(--spacing-xl) !important;
}
.mr-xl {
  margin-right: var(--spacing-xl) !important;
}
.mb-xl {
  margin-bottom: var(--spacing-xl) !important;
}
.mx-xl {
  margin-left: var(--spacing-xl) !important;
  margin-right: var(--spacing-xl) !important;
}
.my-xl {
  margin-top: var(--spacing-xl) !important;
  margin-bottom: var(--spacing-xl) !important;
}
.m-xxl {
  margin: var(--spacing-xxl) !important;
}
.ml-xxl {
  margin-left: var(--spacing-xxl) !important;
}
.mt-xxl {
  margin-top: var(--spacing-xxl) !important;
}
.mr-xxl {
  margin-right: var(--spacing-xxl) !important;
}
.mb-xxl {
  margin-bottom: var(--spacing-xxl) !important;
}
.mx-xxl {
  margin-left: var(--spacing-xxl) !important;
  margin-right: var(--spacing-xxl) !important;
}
.my-xxl {
  margin-top: var(--spacing-xxl) !important;
  margin-bottom: var(--spacing-xxl) !important;
}

/*---------------------------------------------
- Padding
---------------------------------------------*/

.p-none {
  padding: 0 !important;
}
.pl-none {
  padding-left: 0 !important;
}
.pt-none {
  padding-top: 0 !important;
}
.pr-none {
  padding-right: 0 !important;
}
.pb-none {
  padding-bottom: 0 !important;
}
.px-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.p-xxs {
  padding: var(--spacing-xxs) !important;
}
.pl-xxs {
  padding-left: var(--spacing-xxs) !important;
}
.pt-xxs {
  padding-top: var(--spacing-xxs) !important;
}
.pr-xxs {
  padding-right: var(--spacing-xxs) !important;
}
.pb-xxs {
  padding-bottom: var(--spacing-xxs) !important;
}
.px-xxs {
  padding-left: var(--spacing-xxs) !important;
  padding-right: var(--spacing-xxs) !important;
}
.py-xxs {
  padding-top: var(--spacing-xxs) !important;
  padding-bottom: var(--spacing-xxs) !important;
}
.p-xs {
  padding: var(--spacing-xs) !important;
}
.pl-xs {
  padding-left: var(--spacing-xs) !important;
}
.pt-xs {
  padding-top: var(--spacing-xs) !important;
}
.pr-xs {
  padding-right: var(--spacing-xs) !important;
}
.pb-xs {
  padding-bottom: var(--spacing-xs) !important;
}
.px-xs {
  padding-left: var(--spacing-xs) !important;
  padding-right: var(--spacing-xs) !important;
}
.py-xs {
  padding-top: var(--spacing-xs) !important;
  padding-bottom: var(--spacing-xs) !important;
}
.p-sm {
  padding: var(--spacing-sm) !important;
}
.pl-sm {
  padding-left: var(--spacing-sm) !important;
}
.pt-sm {
  padding-top: var(--spacing-sm) !important;
}
.pr-sm {
  padding-right: var(--spacing-sm) !important;
}
.pb-sm {
  padding-bottom: var(--spacing-sm) !important;
}
.px-sm {
  padding-left: var(--spacing-sm) !important;
  padding-right: var(--spacing-sm) !important;
}
.py-sm {
  padding-top: var(--spacing-sm) !important;
  padding-bottom: var(--spacing-sm) !important;
}
.p-md {
  padding: var(--spacing-md) !important;
}
.pl-md {
  padding-left: var(--spacing-md) !important;
}
.pt-md {
  padding-top: var(--spacing-md) !important;
}
.pr-md {
  padding-right: var(--spacing-md) !important;
}
.pb-md {
  padding-bottom: var(--spacing-md) !important;
}
.px-md {
  padding-left: var(--spacing-md) !important;
  padding-right: var(--spacing-md) !important;
}
.py-md {
  padding-top: var(--spacing-md) !important;
  padding-bottom: var(--spacing-md) !important;
}
.p-lg {
  padding: var(--spacing-lg) !important;
}
.pl-lg {
  padding-left: var(--spacing-lg) !important;
}
.pt-lg {
  padding-top: var(--spacing-lg) !important;
}
.pr-lg {
  padding-right: var(--spacing-lg) !important;
}
.pb-lg {
  padding-bottom: var(--spacing-lg) !important;
}
.px-lg {
  padding-left: var(--spacing-lg) !important;
  padding-right: var(--spacing-lg) !important;
}
.py-lg {
  padding-top: var(--spacing-lg) !important;
  padding-bottom: var(--spacing-lg) !important;
}
.p-xl {
  padding: var(--spacing-xl) !important;
}
.pl-xl {
  padding-left: var(--spacing-xl) !important;
}
.pt-xl {
  padding-top: var(--spacing-xl) !important;
}
.pr-xl {
  padding-right: var(--spacing-xl) !important;
}
.pb-xl {
  padding-bottom: var(--spacing-xl) !important;
}
.px-xl {
  padding-left: var(--spacing-xl) !important;
  padding-right: var(--spacing-xl) !important;
}
.py-xl {
  padding-top: var(--spacing-xl) !important;
  padding-bottom: var(--spacing-xl) !important;
}
.p-xxl {
  padding: var(--spacing-xxl) !important;
}
.pl-xxl {
  padding-left: var(--spacing-xxl) !important;
}
.pt-xxl {
  padding-top: var(--spacing-xxl) !important;
}
.pr-xxl {
  padding-right: var(--spacing-xxl) !important;
}
.pb-xxl {
  padding-bottom: var(--spacing-xxl) !important;
}
.px-xxl {
  padding-left: var(--spacing-xxl) !important;
  padding-right: var(--spacing-xxl) !important;
}
.py-xxl {
  padding-top: var(--spacing-xxl) !important;
  padding-bottom: var(--spacing-xxl) !important;
}
