/*---------------------------------------------
- Font families
---------------------------------------------*/

.ff-serif {
  font-family: var(--font-family-serif) !important;
}
.ff-sans-serif {
  font-family: var(--font-family-sans-serif) !important;
}
.ff-code {
  font-family: var(--font-family-mono) !important;
}

/*---------------------------------------------
- Font sizes
---------------------------------------------*/

.txt-xxxl {
  font-size: var(--font-size-xxxl) !important;
}
.txt-xxl {
  font-size: var(--font-size-xxl) !important;
}
.txt-xl {
  font-size: var(--font-size-xl) !important;
}
.txt-lg {
  font-size: var(--font-size-lg) !important;
}
.txt-md {
  font-size: var(--font-size-md) !important;
}
.txt-sm {
  font-size: var(--font-size-sm) !important;
}
.txt-xs {
  font-size: var(--font-size-xs) !important;
}

/*---------------------------------------------
- Text alignment
---------------------------------------------*/

.left {
  text-align: left !important;
}
.right {
  text-align: right !important;
}
.center {
  text-align: center !important;
}
.justify {
  text-align: justify !important;
}

/*---------------------------------------------
- Font styles
---------------------------------------------*/

.italic,
.fs-italic {
  font-style: italic !important;
}

/*---------------------------------------------
- Font weights
---------------------------------------------*/

.fw-1 {
  font-weight: 100 !important;
}
.fw-2 {
  font-weight: 200 !important;
}
.fw-3 {
  font-weight: 300 !important;
}
.fw-4 {
  font-weight: 400 !important;
}
.fw-5 {
  font-weight: 500 !important;
}
.fw-6 {
  font-weight: 600 !important;
}
.fw-7 {
  font-weight: 700 !important;
}
.fw-8 {
  font-weight: 800 !important;
}
.fw-9 {
  font-weight: 900 !important;
}
.bold,
.fw-bold {
  font-weight: bold !important;
}
.fw-normal {
  font-weight: normal !important;
}
.lighter,
.fw-lighter {
  font-weight: lighter !important;
}

/*---------------------------------------------
- Letter spacing
---------------------------------------------*/

.ls-xxs {
  letter-spacing: var(--letter-spacing-xxs) !important;
}
.ls-xs {
  letter-spacing: var(--letter-spacing-xs) !important;
}
.ls-sm {
  letter-spacing: var(--letter-spacing-sm) !important;
}
.ls-md {
  letter-spacing: var(--letter-spacing-md) !important;
}
.ls-lg {
  letter-spacing: var(--letter-spacing-lg) !important;
}
.ls-xl {
  letter-spacing: var(--letter-spacing-xl) !important;
}
.ls-xxl {
  letter-spacing: var(--letter-spacing-xxl) !important;
}

/*---------------------------------------------
- Line heights
---------------------------------------------*/

.lh-xxs {
  line-height: var(--line-height-xxs) !important;
}
.lh-xs {
  line-height: var(--line-height-xs) !important;
}
.lh-sm {
  line-height: var(--line-height-sm) !important;
}
.lh-md {
  line-height: var(--line-height-md) !important;
}
.lh-lg {
  line-height: var(--line-height-lg) !important;
}
.lh-xl {
  line-height: var(--line-height-xl) !important;
}
.lh-xxl {
  line-height: var(--line-height-xxl) !important;
}

/*---------------------------------------------
- Lists
---------------------------------------------*/

.unstyled {
  list-style: none !important;
}

/*---------------------------------------------
- Text colors
---------------------------------------------*/

.primary-lightest,
.hov-primary-lightest:hover {
  color: var(--color-primary-lightest) !important;
}
.primary-lighter,
.hov-primary-lighter:hover {
  color: var(--color-primary-lighter) !important;
}
.primary-light,
.hov-primary-light:hover {
  color: var(--color-primary-light) !important;
}
.primary,
.hov-primary:hover {
  color: var(--color-primary) !important;
}
.primary-dark,
.hov-primary-dark:hover {
  color: var(--color-primary-dark) !important;
}
.primary-darker,
.hov-primary-darker:hover {
  color: var(--color-primary-darker) !important;
}
.primary-darkest,
.hov-primary-darkest:hover {
  color: var(--color-primary-darkest) !important;
}

.secondary-lightest,
.hov-secondary-lightest:hover {
  color: var(--color-secondary-lightest) !important;
}
.secondary-lighter,
.hov-secondary-lighter:hover {
  color: var(--color-secondary-lighter) !important;
}
.secondary-light,
.hov-secondary-light:hover {
  color: var(--color-secondary-light) !important;
}
.secondary,
.hov-secondary:hover {
  color: var(--color-secondary) !important;
}
.secondary-dark,
.hov-secondary-dark:hover {
  color: var(--color-secondary-dark) !important;
}
.secondary-darker,
.hov-secondary-darker:hover {
  color: var(--color-secondary-darker) !important;
}
.secondary-darkest,
.hov-secondary-darkest:hover {
  color: var(--color-secondary-darkest) !important;
}

.info-lightest,
.hov-info-lightest:hover {
  color: var(--color-info-lightest) !important;
}
.info-lighter,
.hov-info-lighter:hover {
  color: var(--color-info-lighter) !important;
}
.info-light,
.hov-info-light:hover {
  color: var(--color-info-light) !important;
}
.info,
.hov-info:hover {
  color: var(--color-info) !important;
}
.info-dark,
.hov-info-dark:hover {
  color: var(--color-info-dark) !important;
}
.info-darker,
.hov-info-darker:hover {
  color: var(--color-info-darker) !important;
}
.info-darkest,
.hov-info-darkest:hover {
  color: var(--color-info-darkest) !important;
}

.success-lightest,
.hov-success-lightest:hover {
  color: var(--color-success-lightest) !important;
}
.success-lighter,
.hov-success-lighter:hover {
  color: var(--color-success-lighter) !important;
}
.success-light,
.hov-success-light:hover {
  color: var(--color-success-light) !important;
}
.success,
.hov-success:hover {
  color: var(--color-success) !important;
}
.success-dark,
.hov-success-dark:hover {
  color: var(--color-success-dark) !important;
}
.success-darker,
.hov-success-darker:hover {
  color: var(--color-success-darker) !important;
}
.success-darkest,
.hov-success-darkest:hover {
  color: var(--color-success-darkest) !important;
}

.warning-lightest,
.hov-warning-lightest:hover {
  color: var(--color-warning-lightest) !important;
}
.warning-lighter,
.hov-warning-lighter:hover {
  color: var(--color-warning-lighter) !important;
}
.warning-light,
.hov-warning-light:hover {
  color: var(--color-warning-light) !important;
}
.warning,
.hov-warning:hover {
  color: var(--color-warning) !important;
}
.warning-dark,
.hov-warning-dark:hover {
  color: var(--color-warning-dark) !important;
}
.warning-darker,
.hov-warning-darker:hover {
  color: var(--color-warning-darker) !important;
}
.warning-darkest,
.hov-warning-darkest:hover {
  color: var(--color-warning-darkest) !important;
}

.danger-lightest,
.hov-danger-lightest:hover {
  color: var(--color-danger-lightest) !important;
}
.danger-lighter,
.hov-danger-lighter:hover {
  color: var(--color-danger-lighter) !important;
}
.danger-light,
.hov-danger-light:hover {
  color: var(--color-danger-light) !important;
}
.danger,
.hov-danger:hover {
  color: var(--color-danger) !important;
}
.danger-dark,
.hov-danger-dark:hover {
  color: var(--color-danger-dark) !important;
}
.danger-darker,
.hov-danger-darker:hover {
  color: var(--color-danger-darker) !important;
}
.danger-darkest,
.hov-danger-darkest:hover {
  color: var(--color-danger-darkest) !important;
}

.gray-lightest,
.hov-gray-lightest:hover {
  color: var(--color-gray-lightest) !important;
}
.gray-lighter,
.hov-gray-lighter:hover {
  color: var(--color-gray-lighter) !important;
}
.gray-light,
.hov-gray-light:hover {
  color: var(--color-gray-light) !important;
}
.gray,
.hov-gray:hover {
  color: var(--color-gray) !important;
}
.gray-dark,
.hov-gray-dark:hover {
  color: var(--color-gray-dark) !important;
}
.gray-darker,
.hov-gray-darker:hover {
  color: var(--color-gray-darker) !important;
}
.gray-darkest,
.hov-gray-darkest:hover {
  color: var(--color-gray-darkest) !important;
}

.transparent,
.hov-transparent:hover {
  color: transparent !important;
}
.black,
.hov-black:hover {
  color: var(--color-black) !important;
}
.white,
.hov-white:hover {
  color: var(--color-white) !important;
}

/*---------------------------------------------
- Text decoration
---------------------------------------------*/

.td-none,
.hov-td-none:hover {
  text-decoration: none !important;
}
.td-line,
.hov-td-line:hover {
  text-decoration: line-through !important;
}
.td-underline,
.hov-td-underline:hover {
  text-decoration: underline !important;
}

/*---------------------------------------------
- Text transform
---------------------------------------------*/

.upper {
  text-transform: uppercase !important;
}
.lower {
  text-transform: lowercase !important;
}
.capital {
  text-transform: capitalize !important;
}
