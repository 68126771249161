/*---------------------------------------------
- Widths
---------------------------------------------*/

.w-0 {
  width: 0% !important;
}
.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-33 {
  width: 33.3% !important;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;
}
.w-66 {
  width: 66.6% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}

/*---------------------------------------------
- Max widths
---------------------------------------------*/

.mw-0 {
  max-width: 0% !important;
}
.mw-5 {
  max-width: 5% !important;
}
.mw-10 {
  max-width: 10% !important;
}
.mw-15 {
  max-width: 15% !important;
}
.mw-20 {
  max-width: 20% !important;
}
.mw-25 {
  max-width: 25% !important;
}
.mw-30 {
  max-width: 30% !important;
}
.mw-33 {
  max-width: 33.3% !important;
}
.mw-40 {
  max-width: 40% !important;
}
.mw-50 {
  max-width: 50% !important;
}
.mw-60 {
  max-width: 60% !important;
}
.mw-66 {
  max-width: 66.6% !important;
}
.mw-70 {
  max-width: 70% !important;
}
.mw-75 {
  max-width: 75% !important;
}
.mw-80 {
  max-width: 80% !important;
}
.mw-90 {
  max-width: 90% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mw-auto {
  max-width: auto !important;
}
