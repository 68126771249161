/*---------------------------------------------
- Display
---------------------------------------------*/

.db {
  display: block !important;
}
.di {
  display: inline !important;
}
.dib {
  display: inline-block !important;
}
.df {
  display: flex !important;
}
.dfb {
  display: flex-block !important;
}
.dt {
  display: table !important;
}
.dtc {
  display: table-cell !important;
}
.dn {
  display: none !important;
}

/*---------------------------------------------
- Floats
---------------------------------------------*/

.fl {
  float: left !important;
}
.fr {
  float: right !important;
}
.fn {
  float: none !important;
}

/*---------------------------------------------
- Opacity
---------------------------------------------*/

.o-0 {
  opacity: 0 !important;
}
.o-025 {
  opacity: 0.025 !important;
}
.o-05 {
  opacity: 0.05 !important;
}
.o-10 {
  opacity: 0.1 !important;
}
.o-20 {
  opacity: 0.2 !important;
}
.o-30 {
  opacity: 0.3 !important;
}
.o-40 {
  opacity: 0.4 !important;
}
.o-50 {
  opacity: 0.5 !important;
}
.o-60 {
  opacity: 0.6 !important;
}
.o-70 {
  opacity: 0.7 !important;
}
.o-80 {
  opacity: 0.8 !important;
}
.o-90 {
  opacity: 0.9 !important;
}
.o-100 {
  opacity: 1 !important;
}

/*---------------------------------------------
- Overflow
---------------------------------------------*/

.of-visible {
  overflow: visible !important;
}
.of-hidden {
  overflow: hidden !important;
}
.of-scroll {
  overflow: scroll !important;
}
.of-auto {
  overflow: auto !important;
}

/*---------------------------------------------
- Positioning
---------------------------------------------*/

.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.fixed {
  position: fixed !important;
}
.sticky {
  position: sticky !important;
}

/*---------------------------------------------
- Outline
---------------------------------------------*/

.outline-none:focus {
  outline: none !important;
}

/*---------------------------------------------
- Vertical alignment
---------------------------------------------*/

.v-base {
  vertical-align: baseline !important;
}
.v-bot {
  vertical-align: bottom !important;
}
.v-mid {
  vertical-align: middle !important;
}
.v-top {
  vertical-align: top !important;
}
.v-sub {
  vertical-align: sub !important;
}
.v-super {
  vertical-align: super !important;
}
.v-text-top {
  vertical-align: text-top !important;
}
.v-text-bot {
  vertical-align: text-bottom !important;
}

/*---------------------------------------------
- Visibility
---------------------------------------------*/

.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}

/*---------------------------------------------
- Whitespace
---------------------------------------------*/

.ws-pre {
  white-space: pre !important;
}
.ws-nowrap {
  white-space: nowrap !important;
}
.ws-normal {
  white-space: normal !important;
}

/*---------------------------------------------
- Z-Index
---------------------------------------------*/

.z-0 {
  z-index: 0 !important;
}
.z-100 {
  z-index: 100 !important;
}
.z-200 {
  z-index: 200 !important;
}
.z-300 {
  z-index: 300 !important;
}
.z-400 {
  z-index: 400 !important;
}
.z-500 {
  z-index: 500 !important;
}
.z-600 {
  z-index: 600 !important;
}
.z-700 {
  z-index: 700 !important;
}
.z-800 {
  z-index: 800 !important;
}
.z-900 {
  z-index: 900 !important;
}
.z-1000 {
  z-index: 1000 !important;
}
