.h-0 {
  height: 0% !important;
}
.h-5 {
  height: 5% !important;
}
.h-10 {
  height: 10% !important;
}
.h-15 {
  height: 15% !important;
}
.h-20 {
  height: 20% !important;
}
.h-25 {
  height: 25% !important;
}
.h-30 {
  height: 30% !important;
}
.h-33 {
  height: 33.3% !important;
}
.h-40 {
  height: 40% !important;
}
.h-50 {
  height: 50% !important;
}
.h-60 {
  height: 60% !important;
}
.h-66 {
  height: 66.6% !important;
}
.h-70 {
  height: 70% !important;
}
.h-75 {
  height: 75% !important;
}
.h-80 {
  height: 80% !important;
}
.h-90 {
  height: 90% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
