/*---------------------------------------------
- Align content
---------------------------------------------*/

.ac-start {
  align-content: flex-start !important;
}
.ac-end {
  align-content: flex-end !important;
}
.ac-center {
  align-content: center !important;
}
.ac-stretch {
  align-content: stretch !important;
}
.ac-around {
  align-content: space-around !important;
}
.ac-between {
  align-content: space-between !important;
}

/*---------------------------------------------
- Align items
---------------------------------------------*/

.ai-start {
  align-items: flex-start !important;
}
.ai-end {
  align-items: flex-end !important;
}
.ai-center {
  align-items: center !important;
}
.ai-baseline {
  align-items: baseline !important;
}
.ai-stretch {
  align-items: stretch !important;
}

/*---------------------------------------------
- Align self
---------------------------------------------*/

.as-start {
  align-self: flex-start !important;
}
.as-end {
  align-self: flex-end !important;
}
.as-center {
  align-self: center !important;
}
.as-baseline {
  align-self: baseline !important;
}
.as-stretch {
  align-self: stretch !important;
}

/*---------------------------------------------
- Flex shorthand
---------------------------------------------*/

.f-1 {
  flex: 1 !important;
}
.f-2 {
  flex: 2 !important;
}
.f-3 {
  flex: 3 !important;
}
.f-4 {
  flex: 4 !important;
}
.f-5 {
  flex: 5 !important;
}
.f-6 {
  flex: 6 !important;
}

/*---------------------------------------------
- Flex direction
---------------------------------------------*/

.fd-row {
  flex-direction: row !important;
}
.fd-row-reverse {
  flex-direction: row-reverse !important;
}
.fd-col {
  flex-direction: column !important;
}
.fd-col-reverse {
  flex-direction: column-reverse !important;
}
.fd-start {
  flex-direction: flex-start !important;
}
.fd-end {
  flex-direction: flex-end !important;
}

/*---------------------------------------------
- Flex order
---------------------------------------------*/

.fo-first {
  flex-order: -1 !important;
}
.fo-last {
  flex-order: 1 !important;
}

/*---------------------------------------------
- Flex grow
---------------------------------------------*/

.fg-1 {
  flex-grow: 1 !important;
}
.fg-2 {
  flex-grow: 2 !important;
}
.fg-3 {
  flex-grow: 3 !important;
}
.fg-4 {
  flex-grow: 4 !important;
}
.fg-5 {
  flex-grow: 5 !important;
}
.fg-6 {
  flex-grow: 6 !important;
}

/*---------------------------------------------
- Flex wrap
---------------------------------------------*/

.fw-wrap {
  flex-wrap: wrap !important;
}
.fw-reverse {
  flex-wrap: reverse !important;
}
.fw-none {
  flex-wrap: nowrap !important;
}

/*---------------------------------------------
- Justify content
---------------------------------------------*/

.jc-start {
  justify-content: flex-start !important;
}
.jc-end {
  justify-content: flex-end !important;
}
.jc-center {
  justify-content: center !important;
}
.jc-between {
  justify-content: space-between !important;
}
.jc-around {
  justify-content: space-around !important;
}
